
import { computed, defineComponent, reactive, ref } from 'vue'
import { ElForm } from 'element-plus'

import { emailPattern } from '@/helpers/validation'

import { isValidationError } from '@/models/store'

import Auth from '@/layouts/Auth.vue'

import { useAuthStore } from '@/store/auth'

export default defineComponent({
  name: 'Dashboard',
  components: {
    Auth,
  },
  setup() {
    const authStore = useAuthStore()
    const signInModule = authStore.signInModule

    const isLoading = computed(() => signInModule.isLoading)
    const formErrors = computed(() => {
      return signInModule.error && isValidationError(signInModule.error)
        ? signInModule.error
        : {}
    })

    const signInFormRef = ref<InstanceType<typeof ElForm> | null>(null)

    const signInData = reactive({ email: '', password: '' })
    const signIn = () => {
      signInFormRef.value?.validate(valid => {
        return valid && authStore.signIn(signInData)
      })
    }

    return {
      signIn,
      formErrors,
      isLoading,
      signInData,
      signInFormRef,
      emailPattern,
      rules: {
        email: [
          {
            required: true,
            message: 'Required',
          },
          // {
          //   type: String,
          //   message: 'Invalid email',
          // },
          {
            pattern: emailPattern,
            message: 'Invalid email',
          },
        ],
        password: [
          {
            required: true,
            message: 'Required',
          },
          {
            min: 6,
            message: 'Must be at least 6 characters',
          },
        ],
      },
    }
  },
})
