import { PaginationMeta } from './api'

export type ValidationErrors<FieldTypes extends string = string> = {
  [key in FieldTypes]?: string
}

export type ModuleError<FieldTypes extends string = string> =
  | string
  | ValidationErrors<FieldTypes>

export const isValidationError = (
  err: ModuleError
): err is ValidationErrors => {
  return typeof err === 'object'
}

export interface CommonModule<ErrorT = string> {
  isLoading: boolean
  error: null | ErrorT
  success: boolean
}

/**
 * Module for updating multiple records/fields at the time.
 * For a record `record.id` is commonly used as `Criteria`.
 * For record fields `field key` is used as `Criteria`
 */
export interface MultipleUpdatesModule<
  Criteria extends string = string,
  ErrorT = string
> {
  error: null | ErrorT
  /**
   * Indicates which record/field is beeing updated
   */
  loadingList: Criteria[]
  /**
   * Indicates which record/field was successfully updated
   */
  successList: Criteria[]
}

export interface ModuleWithData<T> extends CommonModule {
  data: null | T
}

export interface ListModule<T> extends CommonModule {
  list: T[]
  isInitialLoadingDone: boolean
}

export interface PaginationModule<T> extends ListModule<T> {
  meta: null | PaginationMeta
}
